const getPath = () => {
  let hasLoc = '';
  const exp = (inp) => {
    hasLoc = inp;
  };
  if (typeof window !== 'undefined') {
    if ('location' in window) {
      exp(window.location.pathname);
    }
  }
  return hasLoc;
};

export default getPath;
