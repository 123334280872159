// query by type and id
export const APIURLQUERYBYTYPEANDIDqpost = 'https://emsvf0vjfg.execute-api.us-west-2.amazonaws.com/qpost';

// GET feed
export const APIURLPOSTFEEDlpost = 'https://wdpvd44mle.execute-api.us-west-2.amazonaws.com/lposts';

// GET category feed
export const APIURLPOSTFEEDCATEGORIESkposts = 'https://nfvruqqam5.execute-api.us-west-2.amazonaws.com/kposts';

/*

// https://nfvruqqam5.execute-api.us-west-2.amazonaws.com/kposts?t=de&d=2599420277711&l=5&k=m

// GET categories

const AWS = require('aws-sdk');
const documentClient = new AWS.DynamoDB.DocumentClient;

exports.handler = function(event, context, callback) {
  let params = {
    statusCode: 200,
    TableName: 'ptb',
    "KeyConditionExpression": 'ty = :ty AND dt < :dt',
    "ExpressionAttributeValues": {
      ":ty" : event.queryStringParameters.t,
      ":dt" : parseInt(event.queryStringParameters.d, 10),
      ":k" : event.queryStringParameters.k
    },
    "ScanIndexForward": false,
    "FilterExpression": "k = :k",
    Limit: event.queryStringParameters.l,
  };
  documentClient.query(params, function(err, data){
    if (err) {
      callback(err, null);
    } else {
      callback(null, data);
    }
  });
};
*/
