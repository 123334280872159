import { useEffect, useReducer } from 'preact/hooks';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import reDirectOnLang from '../../utils/reDirectOnLang';
import Footer from '../footer/Footer.jsx';
import Header from '../header';
import style from './template.css';

const Template = ({ children }) => {
  const connectionsSettings = useConnectionSettings();
  const styleMode = connectionsSettings.darkMode;
  const forceUpdate = useReducer((bool) => !bool)[1];
  const updatePage = () => {
    forceUpdate((n) => !n);
  };
  reDirectOnLang();

  useEffect(() => {
    if (styleMode) {
      const getB = document.getElementsByTagName('html')[0];
      getB.classList.add('dark');
    } else {
      const getB = document.getElementsByTagName('html')[0];
      getB.classList.remove('dark');
    }
  }, [styleMode]);

  useEffect(() => () => ({
    useConnectionSettings,
  }));

  return (
  <div class={styleMode ? style.dmode : ''}>
    <meta itemprop="accessibilityControl" content="fullKeyboardControl" />
    <meta itemprop="accessibilityControl" content="fullMouseControl" />
    <Header upd={updatePage} />
    <div class={style.space} />
    <main>
    <div>{children}</div>
    </main>
    <Footer />
  </div>
  );
};

export default Template;
