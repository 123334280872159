import { Fragment } from 'preact';

import {
  boldStart, boldEnd, italicStart, italicEnd,
} from '../constants/textMarkers';

// machine <== makeItalic <- makeBold <- insertLink (<- DataRender)

export const machine = (inp, markerStart, markerEnd) => {
  const markerLength = 6;
  let linkHrefDescrArr = [];
  let beforeLinkArr = [];
  let tail = '';
  let tracker = [];
  let keysArr = [];
  if (inp.indexOf(markerStart && markerEnd) !== -1) {
    const numberOfLinks = inp.split(markerStart).length - 1;
    for (let i = 0; i < numberOfLinks; i += 1) {
      keysArr = [...keysArr, i];
      const charsTillEnd = inp.indexOf(markerEnd + i);
      const charsTillStart = inp.indexOf(markerStart + i) + markerLength;
      tracker = [...tracker, charsTillEnd];
      const oneLinkArr = inp.substring(charsTillStart, charsTillEnd).split(',,');
      linkHrefDescrArr = [...linkHrefDescrArr, oneLinkArr];
      if (i === 0) {
        beforeLinkArr = [...beforeLinkArr, [inp.substring(0, charsTillStart - markerLength)]];
        if (numberOfLinks === 1) {
          tail = inp.substring(charsTillEnd + markerLength);
        }
      } else {
        beforeLinkArr = [...beforeLinkArr,
          [inp.substring(tracker[i - 1]
          + markerLength, charsTillStart - markerLength)]];
        if (i === (numberOfLinks - 1)) {
          tail = inp.substring(charsTillEnd + markerLength);
        }
      }
    }
  }
  return {
    linkHrefDescrArr, beforeLinkArr, tail, tracker, keysArr,
  };
};

export const makeItalic = (inp, markerStart, markerEnd) => {
  if (inp.indexOf(markerStart && markerEnd) !== -1) {
    const {
      linkHrefDescrArr, beforeLinkArr, tail, tracker, keysArr,
    } = machine(inp, markerStart, markerEnd);
    return (
      <>
        {keysArr.map(((it) => (
          <Fragment key={`i${tracker[it]}`}>
            {beforeLinkArr[it][0]}
            <em>{linkHrefDescrArr[it][0]}</em>
          </Fragment>
        )))}
        {tail}
      </>
    );
  }
  return inp;
};

export const makeBold = (inp, markerStart, markerEnd) => {
  if (inp.indexOf(markerStart && markerEnd) !== -1) {
    const {
      linkHrefDescrArr, beforeLinkArr, tail, tracker, keysArr,
    } = machine(inp, markerStart, markerEnd);
    return (
      <>
        {keysArr.map(((it) => (
          <Fragment key={`b${tracker[it]}`}>
            {makeItalic(beforeLinkArr[it][0], italicStart, italicEnd)}
            <strong>{linkHrefDescrArr[it][0]}</strong>
          </Fragment>
        )))}
        {makeItalic(tail, italicStart, italicEnd)}
      </>
    );
  }
  return makeItalic(inp, italicStart, italicEnd);
};

export const insertLink = (inp, markerStart, markerEnd) => {
  if (inp.indexOf(markerStart && markerEnd) !== -1) {
    const {
      linkHrefDescrArr, beforeLinkArr, tail, tracker, keysArr,
    } = machine(inp, markerStart, markerEnd);
    return (
      <>
        {keysArr.map(((it) => (
          <Fragment key={tracker[it]}>
            {makeBold(beforeLinkArr[it][0], boldStart, boldEnd)}
            <a
              href={linkHrefDescrArr[it][0]}
              target="_blank"
              rel="noopener noreferrer"
              class="tlink"
            >
              {linkHrefDescrArr[it][1]}
            </a>
          </Fragment>
        )))}
        {makeBold(tail, boldStart, boldEnd)}
      </>
    );
  }
  return makeBold(inp, boldStart, boldEnd);
};
