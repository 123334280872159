import { APIURLPOSTFEEDCATEGORIESkposts } from '../constants/urls';

// l -> limit, number of requested posts
async function fetchFeedByCat(ty, dt, k) {
  let arr = [];
  let pos = 0;
  let err = false;
  // search last 1000 entries
  await fetch(`${APIURLPOSTFEEDCATEGORIESkposts}?t=${ty}&d=${dt.toString()}&k=${k}&l=1000}`)
  // await fetch(`${APIURLPOSTFEEDCATEGORIESkposts}?t=${ty}&d=${dt.toString()}&k=${k}`)
    .then((res) => res.json())
    .then((d) => {
      d.Items.forEach((it) => {
        arr = [...arr, it];
      });
      pos = d.LastEvaluatedKey;
    })
    .catch(() => {
      err = true;
    });
  return { arr, pos, err };
}

export default fetchFeedByCat;
