const ShareIcon = () => (
  <svg version="1.1" id="Capa_1" aria-label="share on social media" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;">
    <g><g>
      <path d="M352,256c-23.983,0-45.13,11.569-58.801,29.201l-144.438-55.553c0.146-1.897,0.572-3.714,0.572-5.647
        c0-3.589-0.568-7.025-1.06-10.479l148.009-68.32c13.693,15.487,33.473,25.466,55.717,25.466c41.167,0,74.667-33.5,74.667-74.667
        c0-41.167-33.5-74.667-74.667-74.667S277.333,54.833,277.333,96c0,3.589,0.568,7.025,1.06,10.479l-148.009,68.32
        c-13.693-15.487-33.473-25.466-55.717-25.466C33.5,149.333,0,182.833,0,224c0,41.167,33.5,74.667,74.667,74.667
        c23.983,0,45.13-11.569,58.801-29.201l144.438,55.553c-0.146,1.897-0.572,3.714-0.572,5.647c0,41.167,33.5,74.667,74.667,74.667
        s74.667-33.5,74.667-74.667C426.667,289.5,393.167,256,352,256z" />
  </g></g>
</svg>
);

export default ShareIcon;
