// external links {marker},,{int}
export const markerLinkStart = '11nkS';
export const markerLinkEnd = '11nkE';

// bold
export const boldStart = '22nkS';
export const boldEnd = '22nkE';

// italic
export const italicStart = 'XXnkS';
export const italicEnd = 'XXnkE';
