import { route } from 'preact-router';

const reDirectOnLang = () => {
  if (typeof localStorage !== 'undefined') {
    const setL = localStorage.getItem('la');
    if (setL) {
      if (typeof window !== 'undefined') {
        if ('location' in window) {
          const pathN = window.location.pathname;
          const prePath = pathN.substring(1, 3);
          if (setL === 'en') {
          // if pref lang = en but route de
            if (setL !== prePath) {
              const doRedirect = async () => {
                route(`/en${pathN === '/' ? '' : pathN}`);
              };
              doRedirect()
                .then(() => {
                  window.location.reload();
                });
            }
          // if pre lang = de but route en
          } else if (prePath === 'en') {
            const doRedirect = async () => {
              const newLink = pathN.replace('/en', '');
              route(`${pathN === '/en' ? '/' : newLink}`);
            };
            doRedirect()
              .then(() => {
                window.location.reload();
              }).catch('ok');
          }
        }
      }
    }
  }
};

export default reDirectOnLang;
