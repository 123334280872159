// import { useEffect, useState } from 'preact/hooks';
import { getCurrentUrl } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { Helmet } from 'react-helmet-async';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import FailedToFetch from '../../components/errorMsg/FailedToFetch.jsx';
import Prev from '../../components/prev/Prev.jsx';
import LoadingPage from '../../components/spinner/LoadingPage.jsx';
import Template from '../../components/template/Template.jsx';
import {
  mainURL, metaDescriptionCatMusicDE, metaDescriptionCatMusicEN, metaDescriptionCatNewsDE,
  metaDescriptionCatNewsEN, metaDescriptionCatStuffDE, metaDescriptionCatStuffEN,
} from '../../constants/labelsAndMetas';
import scrollToTop from '../../utils/scrolls';
import style from '../main/main.css';

let feedPosition = 0;

const Cats = ({ lang, k }) => {
  const contDat = useContentData();
  const connectionSettings = useConnectionSettings();
  const [pageTitle, setPageTitle] = useState('');
  const setLangD = contDat.setLang;
  const cont = contDat.contentDataCAT;
  const loadInitialPostsD = contDat.loadInitialPostsCAT;
  const loadMorePostsD = contDat.loadMorePostsCAT;
  const setContentDataD = contDat.setContentDataCAT;
  const contentArrD = contDat.contentArrCAT;
  const darkModeD = connectionSettings.darkMode;
  const loadingErrorD = contDat.loadingError;

  const plus5 = () => { feedPosition += 5; };
  const minus5 = () => { feedPosition -= 5; };

  const proceedPosts = () => {
    plus5();
    if (feedPosition === contentArrD.length) {
      loadMorePostsD(k)
        .then((x) => setContentDataD(x));
    } else {
      setContentDataD(() => contentArrD.slice(feedPosition, feedPosition + 5));
    }
    scrollToTop();
  };

  const previousPosts = () => {
    minus5();
    setContentDataD(() => contentArrD.slice(feedPosition, feedPosition + 5));
    scrollToTop();
  };

  useEffect(() => {
    if (lang === 'de') {
      setLangD('de');
    }
    if (lang === 'en') {
      setLangD('en');
    }
    if (!contentArrD.length) {
      loadInitialPostsD(k);
    }
    if (contentArrD.length) {
      if (contentArrD[0].k !== k) {
        setContentDataD('');
        loadInitialPostsD(k);
      }
    }
  }, [lang,
    setLangD,
    loadInitialPostsD,
    k,
    contentArrD,
    setContentDataD]);

  const mkeMetaDescription = () => {
    let metaDescription = '';
    const expMetaD = (inp) => {
      metaDescription = inp;
    };
    if (k === 's') {
      if (lang === 'de') {
        expMetaD(metaDescriptionCatStuffDE);
      } else {
        expMetaD(metaDescriptionCatStuffEN);
      }
    }
    if (k === 'n') {
      if (lang === 'de') {
        expMetaD(metaDescriptionCatNewsDE);
      } else {
        expMetaD(metaDescriptionCatNewsEN);
      }
    }

    if (k === 'm') {
      if (lang === 'de') {
        expMetaD(metaDescriptionCatMusicDE);
      } else {
        expMetaD(metaDescriptionCatMusicEN);
      }
    }
    return metaDescription;
  };

  useEffect(() => {
    if (lang === 'de') {
      if (k === 's') {
        setPageTitle('beervirus | Zeugs');
      }
      if (k === 'm') {
        setPageTitle('beervirus | Musik');
      }
      if (k === 'n') {
        setPageTitle('beervirus | News');
      }
    } else {
      if (k === 's') {
        setPageTitle('beervirus | stuff');
      }
      if (k === 'm') {
        setPageTitle('beervirus | music');
      }
      if (k === 'n') {
        setPageTitle('beervirus | news');
      }
    }
  }, [lang, k]);

  useEffect(() => () => ({
    useContentData, useConnectionSettings,
  }));

  return (
    <div class={darkModeD ? style.cold : style.coll}>
      {console.log(cont)}
        <Helmet
        htmlAttributes={{ lang }}
      >
        <title>{pageTitle}</title>
        <link rel="canonical" href={`${mainURL}${getCurrentUrl()}`} />
        <meta name="description" content={mkeMetaDescription()} />
      </Helmet>
      <Template>
      {loadingErrorD ? (
          <>
          <FailedToFetch
            lang={lang}
            mode={darkModeD}
          />
          </>
      ) : null}
        {cont ? (
        <div class={style.catfr}>
          {cont.map((it, ind) => (
            <Prev cont={it} ind={ind} lang={lang} />))}
        </div>) : <LoadingPage /> }
        <div class={style.btnmorefr}>
          <button hidden={feedPosition === 0} class={style.btnprevposts} onClick={() => previousPosts()}><span class={style.arrfr}><span class={style.arrle} /></span>previous posts</button>
          <button hidden={feedPosition >= contentArrD.length} class={style.btnmoreposts} onClick={() => proceedPosts()}>load more posts<span class={style.arrfr}><span class={style.arrri} /></span></button>
        </div>
      </Template>
    </div>
  );
};

export default Cats;
