import { useContext, useEffect, useState } from 'preact/hooks';
import fetchFeed from '../utils/fetchFeed';
import fetchFeedByCat from '../utils/fetchFeedByCat';

const { createContext } = require('preact');

const contentContext = createContext();

export const useContentData = () => useContext(contentContext);

function useProvideContextData() {
  // displayed feed
  const [contentData, setContentData] = useState('');
  // saved feed ... prevent reloading of previously fetched data
  const [contentArr, setContentArr] = useState([]);
  const [lastItem, setLastItem] = useState([]);
  const [lang, setLang] = useState(null);

  // post feed by category
  const [contentDataCAT, setContentDataCAT] = useState('');
  // saved feed ... prevent reloading of previously fetched data
  const [contentArrCAT, setContentArrCAT] = useState([]);
  const [lastItemCAT, setLastItemCAT] = useState([]);
  const [loadingError, setLoadingError] = useState(false);

  const loadInitialPosts = () => {
    if (lang && !contentData) {
      console.log('DEV: useContentData.loadInitialPosts()');
      fetchFeed(lang, 2599420277711, 5)
        .then((x) => {
          setContentData(x.arr);
          setContentArr(x.arr);
          if (x.pos !== undefined) {
            setLastItem(() => [x.pos.dt]);
          }
          if (x.err) {
            setLoadingError(true);
          } else {
            setLoadingError(false);
          }
        });
    }
  };

  const loadMorePosts = async () => {
    console.log('DEV: useContentData.loadMorePosts()');
    let parr = [];
    fetchFeed(lang, lastItem[lastItem.length - 1], 5)
      .then((x) => {
        setContentData((prev) => prev.concat(x.arr));
        setContentArr((prev) => prev.concat(x.arr));
        if (x.pos !== undefined) {
          setLastItem(() => lastItem.concat(x.pos.dt));
          parr = x.arr;
        }
      });
    return parr;
  };

  const loadInitialPostsCAT = (k) => {
    if (lang && !contentDataCAT && k) {
      console.log('DEV: useContentData.loadInitialPostsCAT()');
      fetchFeedByCat(lang, 2599420277711, k)
        .then((x) => {
          setContentDataCAT(x.arr);
          setContentArrCAT(x.arr);
          if (x.pos !== undefined) {
            setLastItemCAT(() => [x.pos.dt]);
          }
          if (x.err) {
            setLoadingError(true);
          } else {
            setLoadingError(false);
          }
        });
    }
  };

  const loadMorePostsCAT = async (k) => {
    console.log('DEV: useContentData.loadMorePostsCAT()');
    let parr = [];
    fetchFeedByCat(lang, lastItemCAT[lastItemCAT.length - 1], k)
      .then((x) => {
        setContentDataCAT((prev) => prev.concat(x.arr));
        setContentArrCAT((prev) => prev.concat(x.arr));
        if (x.pos !== undefined) {
          setLastItemCAT(() => lastItem.concat(x.pos.dt));
          parr = x.arr;
        }
      });
    return parr;
  };

  useEffect(() => () => {
    // unsubribe states
    setContentData(null);
    setContentDataCAT(null);
    setLastItem(null);
    setContentArr(null);
    setLang(null);
    setLastItemCAT(null);
  }, []);

  return {
    contentData,
    setContentData,
    setLastItem,
    lastItem,
    setLang,
    loadInitialPosts,
    loadMorePosts,
    contentArr,
    setContentArr,
    lang,
    contentArrCAT,
    contentDataCAT,
    loadInitialPostsCAT,
    loadMorePostsCAT,
    setContentDataCAT,
    loadingError,
    setLoadingError,
  };
}

export function ProvideContentData({ children }) {
  const contentDataBase = useProvideContextData();

  return (
    <contentContext.Provider value={contentDataBase}>
      {children}
    </contentContext.Provider>
  );
}
