import { getCurrentUrl } from 'preact-router';
import { useEffect } from 'preact/hooks';
import { Helmet } from 'react-helmet-async';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import FailedToFetch from '../../components/errorMsg/FailedToFetch.jsx';
import Prev from '../../components/prev/Prev.jsx';
import LoadingPage from '../../components/spinner/LoadingPage.jsx';
import Template from '../../components/template/Template.jsx';
import { mainURL, metaDescriptionHomeDE, metaDescriptionHomeEN } from '../../constants/labelsAndMetas';
import scrollToTop from '../../utils/scrolls';
import style from './main.css';

let feedPosition = 0;

const Main = ({ lang }) => {
  // const [feedPosition, setFeedPosition] = useState(0);
  const contDat = useContentData();
  const connectionSettings = useConnectionSettings();
  const setLangD = contDat.setLang;
  const cont = contDat.contentData;
  const loadInitialPostsD = contDat.loadInitialPosts;
  const loadMorePostsD = contDat.loadMorePosts;
  const setContentDataD = contDat.setContentData;
  const contentArrD = contDat.contentArr;
  const darkModeD = connectionSettings.darkMode;
  const loadingErrorD = contDat.loadingError;

  const plus5 = () => { feedPosition += 5; };
  const minus5 = () => { feedPosition -= 5; };

  const proceedPosts = () => {
    plus5();
    if (feedPosition === contentArrD.length) {
      loadMorePostsD()
        .then((x) => setContentDataD(x));
    } else {
      setContentDataD(() => contentArrD.slice(feedPosition, feedPosition + 5));
    }
    scrollToTop();
  };

  const previousPosts = () => {
    minus5();
    setContentDataD(() => contentArrD.slice(feedPosition, feedPosition + 5));
    scrollToTop();
  };

  useEffect(() => {
    scrollToTop();
    if (lang === 'de') {
      setLangD('de');
    }
    if (lang === 'en') {
      setLangD('en');
    }
    loadInitialPostsD();
  }, [lang,
    setLangD,
    loadInitialPostsD]);

  // ==== DEV MUTED ====
  useEffect(() => () => ({
    // useContentData, useConnectionSettings, scrollToTop,
  }), []);

  return (
    <div class={darkModeD ? style.cold : style.coll}>
      <Helmet
        htmlAttributes={{ lang }}
        meta={[
          { name: 'description', content: lang === 'de' ? metaDescriptionHomeDE : metaDescriptionHomeEN },
        ]}
      >
        <title>beervirus | HOME</title>
        <link rel="canonical" href={`${mainURL}${getCurrentUrl()}`} />
      </Helmet>
      <Template>
        {cont ? (
        <div>
          {loadingErrorD ? (
          <>
          <FailedToFetch
            lang={lang}
            mode={darkModeD}
          />
          </>
          ) : null}
          {cont.map((it, ind) => (
            <Prev cont={it} ind={ind} lang={lang} />))}
        </div>) : <LoadingPage /> }
        <div class={style.btnmorefr}>
          <button name="pagination" hidden={feedPosition === 0} class={style.btnprevposts} onClick={() => previousPosts()} rel="prev"><span class={style.arrfr}><span class={style.arrle} /></span>previous posts</button>
          <button name="pagination" data-pgnbtn hidden={feedPosition >= contentArrD.length} class={style.btnmoreposts} onClick={() => proceedPosts()} rel="next">load more posts<span class={style.arrfr}><span class={style.arrri} /></span></button>
        </div>
      </Template>
    </div>
  );
};

export default Main;
