import { Link } from 'preact-router';
import { useEffect } from 'preact/hooks';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import style from './style.css';

const Prev = ({ cont, ind, lang }) => {
  const connectionSettings = useConnectionSettings();
  const saveDataD = connectionSettings.saveData;
  const slowConnectionD = connectionSettings.slowConnection;
  const noImagesD = connectionSettings.noImages;
  const supportsWebpD = connectionSettings.supportsWebp;
  const dmode = connectionSettings.darkMode;

  const mkeSources = () => {
    const s0 = cont.hi[0];
    const s1 = cont.hi[1];
    const s2 = cont.hi[2];
    const s3 = cont.hi[3];
    let srcs = '';
    if (saveDataD || slowConnectionD) {
      if (supportsWebpD) {
        srcs = s2;
      } else {
        srcs = s0;
      }
    } else if (supportsWebpD) {
      srcs = `${s2} 1x, ${s3} 3x`;
    } else {
      srcs = `${s0} 1x, ${s1} 3x`;
    }
    return srcs;
  };

  useEffect(() => () => ({
    useConnectionSettings,
  }));

  return (
    <section class="presec">
      <Link class={dmode ? style.f1frd : style.f1fr} href={`${lang === 'en' ? '/en/blog' : '/blog'}/${cont.s}`}>
        <div class={style.f2m}>
          <div class={style.f3t} />
          <div class={style.f3d}>
            <div class={style.f4t}>
              <div class={style.f5t}>
                <div class={dmode ? style.col1d : style.col1l}>
                  <span class={style.date}><time dateTime={new Date(cont.dt).toISOString()}>{new Date(cont.dt).toLocaleDateString('de')}</time></span>
                  <span class={style.cat}> |
                    {cont.k === 'n' ? ' NEWS' : null}
                    {cont.k === 's' ? ' STUFF' : null}
                    {cont.k === 'm' ? ' MUSIC' : null}
                  </span>
                  {cont.a ? (
                    <>{' > '}
                    <span class={style.auth}>
                  {' '}{cont.a}
                    </span>
                    </>
                  ) : null}
                </div>
              </div>
              <h2 class={style.f5d}>
                <span class={style.hdline}><span class={dmode ? style.col2d : style.col2l}>{cont.h}</span></span></h2>
              </div>
              <div class={style.f4d}><p class={dmode ? style.col3d : style.col3l}>{cont.l}</p></div>
            </div>
          <div class={style.f3b} />
        </div>
      <div class={style.f2r}>
        {!noImagesD && cont.hi[2] ? (
          <div>
            <figure
              aria-label={lang === 'de' ? 'Titelbild' : 'cover image'}
            >
              <img
                class={style.previmg}
                srcset={mkeSources()}
                src={cont.hi[1]}
                alt={cont.hi[5]}
                width="768"
                height={768 / cont.hi[11]}
                loading={ind < 1 ? 'auto' : 'lazy'}
              />
            </figure>
          </div>
        ) : null}
      </div>
    </Link>
  </section>
  );
};

export default Prev;
