import { Fragment } from 'preact';
import { getCurrentUrl, Link } from 'preact-router';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Suspense, lazy } from 'preact/compat';
import { Helmet } from 'react-helmet-async';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import Template from '../../components/template/Template.jsx';
import { markerLinkEnd, markerLinkStart } from '../../constants/textMarkers';
import { APIURLQUERYBYTYPEANDIDqpost } from '../../constants/urls';
import { insertLink } from '../../utils/modifyDOM';
import PageContainer from '../../components/template/PageContainer.jsx';
import LoadingPage from '../../components/spinner/LoadingPage.jsx';
import style from './postStyle.css';
import scrollToTop from '../../utils/scrolls';
import ShareIcon from './share/icons/ShareIcon.jsx';
import { defaultPostImage, mainURL, publisherLogo } from '../../constants/labelsAndMetas';
import enlarge from './icons/enlarge.svg';
import zoomout from './icons/zoomout.svg';
import FailedToFetch from '../../components/errorMsg/FailedToFetch.jsx';
import Err404 from '../err404/Err404.jsx';
// import anchor from '../../assets/icons/favicon-32x32.png'

const YouTube = lazy(() => import('./player/YouTube.jsx'));
const SoundCloud = lazy(() => import('./player/SoundCloud.jsx'));
const Facebook = lazy(() => import('./player/Facebook.jsx'));
const Vimeo = lazy(() => import('./player/Vimeo.jsx'));
const TwitterFeed = lazy(() => import('./twitter/TwitterFeed'));
const TwitterTweet = lazy(() => import('./twitter/TwitterTweet'));
const Share2 = lazy(() => import('./share/Share2.jsx'));

const Post = ({ ty, dt }) => {
  const [con, setCon] = useState('');
  const [scrollProgress, setScrollProgress] = useState(null);
  const [isScreen, setIsScreen] = useState(true);
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const [is404, setIs404] = useState(false);
  const contDat = useContentData();
  const contArr = contDat.contentArr;
  const contArrCAT = contDat.contentArrCAT;
  const setLangD = contDat.setLang;
  const getId = dt.substr(dt.length - 13);
  const langD = contDat.lang;
  const loadingErrorD = contDat.loadingError;
  const setLoadingErrorD = contDat.setLoadingError;

  const connectionSettings = useConnectionSettings();
  const darkM = connectionSettings.darkMode;
  const saveDataD = connectionSettings.saveData;
  const slowConnectionD = connectionSettings.slowConnection;
  const noImagesD = connectionSettings.noImages;
  const supportsWebpD = connectionSettings.supportsWebp;
  // const loadLazyIMGD = connectionSettings.loadLazyIMG;

  const openShareRef = useRef();
  // let imgArr = [];
  const imgRefs = useRef([]);

  useEffect(() => {
    setLangD(ty);
    // look for state in useContentData.contentArrCat else fetch
    // ... article data via cat pages
    const lookForStateInCatArr = () => {
      if (contArrCAT.length) {
        contArrCAT.forEach((it) => {
          if (it.dt.toString() === getId) {
            setCon(it);
          }
        });
      } else {
        fetch(`${APIURLQUERYBYTYPEANDIDqpost}?t=${ty}&d=${getId}`)
          .then((re) => re.json())
          .then((xs) => {
            if (!xs.length) {
              setIs404(() => true);
            } else {
              setCon(xs[0]);
            }
          })
          .catch(() => {
            setLoadingErrorD(true);
            return true;
          });
      }
    };

    // look for state in useContentData.contentArr else fetch
    // ... article data saved via HOME
    if (contArr.length) {
      contArr.forEach((it) => {
        if (it.dt.toString() === getId) {
          setCon(it);
        } else {
          lookForStateInCatArr();
        }
      });
    } else {
      fetch(`${APIURLQUERYBYTYPEANDIDqpost}?t=${ty}&d=${getId}`)
        .then((re) => re.json())
        .then((xs) => {
          if (!xs.length) {
            setIs404(() => true);
          } else {
            setCon(xs[0]);
          }
        })
        .catch(() => {
          setLoadingErrorD(true);
          return true;
        });
    }
  }, [contArr,
    getId,
    ty,
    setLangD,
    setLoadingErrorD,
    contArrCAT]);

  const mkeHdrSrc = (inp) => {
    const s0 = inp[0];
    const s1 = inp[1];
    const s2 = inp[2];
    const s3 = inp[3];
    let iSrc = '';
    const sTS = (ii) => {
      iSrc = ii;
    };
    if (inp) {
      if (saveDataD || slowConnectionD) {
        if (supportsWebpD) {
          sTS(s2);
        } else {
          sTS(s0);
        }
      } else if (supportsWebpD) {
        sTS(`${s2} 768w, ${s3} 1440w`);
      } else {
        sTS(`${s0} 768w, ${s1} 1440w`);
      }
    }
    return iSrc;
  };

  const mkeSrc = (inp) => {
    // srcset post image
    const s0 = inp[2][0];
    const s1 = inp[2][1];
    const s2 = inp[2][2];
    const s3 = inp[2][3];
    let iSrc = '';
    const sTS = (ii) => {
      iSrc = ii;
    };
    if (inp[1] === 'i' && inp[2].length) {
      if (saveDataD || slowConnectionD) {
        if (supportsWebpD) {
          sTS(s2);
        } else {
          sTS(s0);
        }
      } else if (supportsWebpD) {
        sTS(`${s2} 768w, ${s3} 1440w`);
      } else {
        sTS(`${s0} 768w, ${s1} 1440w`);
      }
    }
    return iSrc;
  };

  const scrlPos = () => {
    const winScroll = window.pageYOffset;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    setScrollProgress((winScroll / height) * 100);
  };

  useEffect(() => {
    scrollToTop();
    if (typeof window !== 'undefined') {
      // show progress bar only on desktop view
      if (window.innerWidth > 1024) {
        window.addEventListener('scroll', scrlPos);
        scrlPos();
      }
    }

    return () => {
      window.removeEventListener('scroll', scrlPos);
      setCon(null);
      setScrollProgress(null);
      setShareIsOpen(null);
      setIsScreen(null);
      // dev
      return {
        useConnectionSettings, useContentData,
      };
      // ===
    };
  }, []);

  const openShare = () => {
    setShareIsOpen((prev) => !prev);
    if (shareIsOpen) {
      openShareRef.current.setAttribute('aria-haspopup', true);
    } else {
      openShareRef.current.setAttribute('aria-haspopup', false);
    }
  };

  const onZoomImage = (inp) => {
    const getImageID = inp.target.getAttribute('data-id');
    const imageIsOpen = inp.target.getAttribute('data-zoom');
    const iconSource = inp.target;
    const getImage = document.getElementById(getImageID);
    if (imageIsOpen === 'on') {
      getImage.classList.remove(style.zoomin);
      inp.target.setAttribute('data-zoom', 'off');
      iconSource.src = enlarge;
      inp.target.setAttribute('aria-label', 'enlarge image');
    } else {
      getImage.classList.add(style.zoomin);
      inp.target.setAttribute('data-zoom', 'on');
      iconSource.src = zoomout;
      inp.target.setAttribute('aria-label', 'zoom out image');
    }
  };

  return (
    <Template>
      <Helmet
        htmlAttributes={{ lang: langD }}
        meta={[
          { name: 'description', content: con.m },
          { property: 'og:type', content: 'article' },
          { property: 'og:locale', content: langD },
          { property: 'og:title', content: con.h },
          { property: 'og:description', content: con.l },
          { property: 'og:url', content: mainURL + getCurrentUrl() },
          { property: 'og:image', content: `${con.hi ? con.hi[0] : defaultPostImage}` },
          { property: 'og:image:type', content: 'image/jpeg' },
          { property: 'og:image:width', content: '512' },
          { property: 'og:image:alt', content: con.hi ? con.hi[5] : '' },
        ]}
        script={[{
          type: 'application/ld+json',
          innerHTML: `{ "@context": "http://schema.org",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${mainURL + getCurrentUrl()}"
          },
          "headline": "${con.h}",
          "datePublished": "${con.dt ? new Date(con.dt).toISOString() : null}",
          "dateModified": "${con.u ? new Date(con.u).toISOString() : null}",
          "image": "${con.hi ? con.hi[1] : defaultPostImage}",
          "author": {
            "@type": "Person",
            "name": "${con.a}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "beervirus.com",
            "logo": {
              "@type": "ImageObject",
              "url": "${publisherLogo}"
            }
          }
         }`,
        }]}
      >
        <title>{`${con.h} | beervirus.com`}</title>
        <link rel="canonical" href={`${mainURL}${getCurrentUrl()}`} />
      </Helmet>
      {loadingErrorD ? (
          <>
          <FailedToFetch
            lang={langD}
            mode={darkM}
          />
          </>
      ) : null}
      <PageContainer>
          <div class={style.progbarfr}>
            <div class={style.progbar} style={{ width: `${scrollProgress > 25 ? scrollProgress : 0}%` }} />
            </div>
      {con ? (
        <div class={isScreen ? style.screenv : style.printv}>
          <div class={darkM ? style.dmode : style.lmode}>
          {!noImagesD && con.hi[2] ? (
            <figure
              class={style.pfig}
              id='hdrimg'
              itemProp="image" itemScope="" itemType="https://schema.org/ImageObject"
            >
              <div class={style.opnimgbtn}><img width="50" height="50" aria-label={langD === 'de' ? 'Bild zoomen' : 'zoom image'} title={langD === 'de' ? 'Bild zoomen' : 'zoom image'} onClick={(ev) => onZoomImage(ev)} onKeyPress={(ev) => onZoomImage(ev)} tabIndex='0' role="button" class={style.enlargebtn} data-id='hdrimg' alt="zoom image" data-zoom='off' src={enlarge} /></div>

              <img
                class={style.pstimg}
                srcset={mkeHdrSrc(con.hi)}
                itemProp="url contentUrl"
                src={con.hi[1]}
                alt={con.hi[5]}
                width="1440"
                height={1440 / con.hi[11]}
                // sizes="(max-width: 512px) 512px, 1440px"
                // sizes="(max-width: 768px) 100vw, (min-width: 1130px) 959px, calc(100vw - 8em)"
                // sizes="(max-width: 768px) 100vw, 959px"
                sizes="(max-width: 768px) 100vw, (min-width: 1130px) 959px, calc(100vw - 8em)"

                // sizes="(max-width: 768px) 100vw, 959px"
                // loading="lazy"
              />

              <meta itemProp="description" content={con.hi[5]} />
              <meta itemProp="width" content={con.hi[4] === 'l' ? '1440' : 1440 / con.hi[11]} />
              <meta itemProp="height" content={con.hi[4] === 'l' ? 1440 / con.hi[11] : '1440'} />
              <meta itemprop="encodingFormat" content="image/jpeg" />

              {con.hi[6] || con.hi[7] || con.hi[9] ? (
                <figcaption class={style.capfr}>
              {con.hi[6] ? <div class={style.pfcap}>
                {con.hi[6]}
                  <meta itemProp="caption" content={con.hi[6]} />
                </div> : null }

                {con.hi[7] ? (
                <>{con.hi[8] ? (
                  <div
                    class={style.pimgcrd}
                    itemProp="author" itemScope="" itemType="https://schema.org/Person"
                  >
                    {ty === 'de' ? 'Bild: ' : 'Image: '}
                    <a href={con.hi[7] === 'me' ? mainURL : con.hi[8]}>
                      <span itemProp="name">
                      {con.hi[7] === 'me' ? 'beervirus.com' : con.hi[7]}
                      </span>
                      <link itemProp="url" href={con.hi[7] === 'me' ? 'https://beervirus.com' : con.hi[8]} />
                    </a>
                  </div>
                ) : (
                  <div
                    class={style.pimgcrd}
                    itemProp="author" itemScope="" itemType="https://schema.org/Person"
                  >{ty === 'de' ? 'Bild: ' : 'Image: '}
                  <span itemProp="name">
                    {con.hi[7] === 'me' ? 'beervirus.com' : con.hi[7]}
                  </span>
                  <link itemProp="url" href={con.hi[7] === 'me' ? 'https://beervirus.com' : con.hi[8]} />
                  </div>)}</>
                ) : null}

              {con.hi[9] ? (
                <>
                  {con.hi[9] === 'ccbysa40' ? (
                    <div class={style.pimgcrd}>
                      <a href='https://creativecommons.org/licenses/by-sa/4.0/'>CC-BY-SA 4.0</a>
                      <meta itemProp="license" content="https://creativecommons.org/licenses/by-sa/4.0/" />
                      <meta itemProp="acquireLicensePage" content="https://creativecommons.org/licenses/by-sa/4.0/" />
                    </div>
                  ) : null}
                    {con.hi[9] && con.hi[9] !== 'ccbysa40' ? (
                    <div class={style.pimgcrd}>
                      <a href={con.hi[10]}>{con.hi[9]}</a>
                      <meta itemProp="license" content={con.hi[10]} />
                      <meta itemProp="acquireLicensePage" content={con.hi[10]} />
                    </div>
                    ) : null}
                </>
              ) : (
              <>
                {con.hi[10] ? (
                <div class={style.pimgcrd}>
                  <a href={con.hi[10]}>{con.hi[9]}</a>
                  <meta itemProp="license" content={con.hi[10]} />
                    <meta itemProp="acquireLicensePage" content={con.hi[10]} />
                  </div>
                ) : (
                <div class={style.pimgcrd}>{con.hi[9]}</div>)}
              </>)}

              </figcaption>
              ) : null}
            </figure>

          ) : null}
          <div id="speak" class={style.txtfr}>
          <div class={style.datecat}>
          <span class={style.date}><time dateTime={new Date(con.dt).toISOString()}>{new Date(con.dt).toLocaleDateString('de')}</time></span>|
          <span class={style.catfr}>
            <span class={style.catbx}>
              <Link href={ty === 'de' ? `/blog/c/${con.k}` : `/en/blog/c/${con.k}`}>
                {con.k === 'm' ? 'MUSIC' : null}
                {con.k === 'n' ? 'NEWS' : null}
                {con.k === 's' ? 'STUFF' : null}
              </Link>
            </span>
          </span>
              {con.a ? (<>{'> '}<span class={style.auth}>{con.a}</span></>) : null}
          </div>

          <h1 class={style.ph1}>{con.h}</h1>
          {con.l ? (
            <div class={style.lead}>
              {con.l}
            </div>
          ) : null}
          {con.c ? con.c.map((it) => (
            <Fragment key={it[0]}>

            {it[1] === 't' && it[2] !== '' ? (
              <p class={style.pp1}>{insertLink(it[2], markerLinkStart, markerLinkEnd)}</p>
            ) : null}

          {it[1] === 'h2' && it[2] !== '' ? (
            <h2 class={it[4] ? it[4] : ''}>{it[2]}</h2>
          ) : null}

          {it[1] === 'h3' && it[2] !== '' ? (
            <h3 class={it[4] ? it[4] : ''}>{it[2]}</h3>
          ) : null}

          {it[1] === 'q' && it[2] !== '' ? (
            <blockquote class={style.pq}>
              <p class={style.pqq}>{it[2]}</p>
              {it[4] !== undefined ? <p class={style.pqs}>{it[4]}</p> : null}
            </blockquote>
          ) : null}

          {it[1] === 'l' && it[4] !== '' ? (
            <>
              {it[2] ? <p class={style.plh}>{insertLink(it[2], markerLinkStart, markerLinkEnd)}</p> : null}
                <ul class={style.pl}>
                  {it[4] ? it[4].map((ii) => (
                    <>
                      {ii ? <li>{insertLink(ii, markerLinkStart, markerLinkEnd)}</li> : null}
                    </>
                  )) : null}
            </ul>
            </>
          ) : null}

          {!noImagesD && it[1] === 'i' ? (
          <div class={style.figfr}>
            <figure
              class={it[2][4] === 'l' ? style.pfig : style.pfigport}
              id={it[0]}
              itemProp="image" itemScope="" itemType="https://schema.org/ImageObject"
            > {it[2][4] === 'l' ? (
              <div class={style.opnimgbtn}>
              <img width="50" height="50" aria-label={langD === 'de' ? 'Bild zoomen' : 'zoom image'} title={langD === 'de' ? 'Bild zoomen' : 'zoom image'} tabIndex='0' onClick={(ev) => onZoomImage(ev)} onKeyPress={(ev) => onZoomImage(ev)} role="button" class={style.enlargebtn} data-id={it[0]} alt="zoom image" data-zoom='off' src={enlarge} />
            </div>
            ) : null
            }
              <img
                ref={(ref) => imgRefs.current.push(ref) }
                class={it[2][4] === 'l' ? style.pstimg : style.pstimgport}
                srcset={mkeSrc(it)}
                itemProp="url contentUrl"
                src={it[2][1]}
                alt={it[2][5]}
                width={it[2][4] === 'l' ? '1440' : 1440 * it[2][11]}
                height={it[2][4] === 'l' ? 1440 / it[2][11] : '1440'}
                sizes={it[2][4] === 'l' ? '(max-width: 768px) 100vw, (min-width: 1130px) 959px, calc(100vw - 8em)'
                  : 'max-width: 768px) 100vw, (min-width: 1130px) 626px'}
                // sizes="(min-width: 1130px) 959px, (max-width: 768px) 100vw, calc(100vw - 8em)"
                loading='lazy'
               />
              <meta itemProp="description" content={it[2][5]} />
              <meta itemProp="width" content={it[2][4] === 'l' ? '1440' : 1440 * it[2][11]} />
              <meta itemProp="height" content={it[2][4] === 'l' ? 1440 / it[2][11] : '1440'} />
              <meta itemprop="encodingFormat" content="image/jpeg" />
                {it[2][6] || it[2][7] || it[2][9] ? (
                  <figcaption class={style.capfr}>
                  {it[2][6] ? <div class={style.pfcap}>
                    {it[2][6]}
                    <meta itemProp="caption" content={it[2][6]} />
                  </div> : null }
                {it[2][7] ? (
                <>{it[2][8] ? (
                  <div
                    class={style.pimgcrd}
                    itemprop="author" itemscope="" itemtype="https://schema.org/Person"
                  >
                    {ty === 'de' ? 'Bild: ' : 'Image: '}
                  <a href={it[2][7] === 'me' ? mainURL : it[2][8]}>
                    <span itemProp="name">
                      {it[2][7] === 'me' ? 'beervirus.com' : it[2][7]}
                    </span>
                      <link itemProp="url" href={it[2][7] === 'me' ? 'https://beervirus.com' : it[2][8]} />
                    </a>
                  </div>
                ) : (
                <div
                class={style.pimgcrd}
                itemprop="author" itemscope="" itemtype="https://schema.org/Person"
                >
                  {ty === 'de' ? 'Bild: ' : 'Image: '}
                  <span itemProp="name">
                  {it[2][7] === 'me' ? 'beervirus.com' : it[2][7]}
                  </span>
                  <link itemProp="url" href={it[2][7] === 'me' ? 'https://beervirus.com' : it[2][8]} />

                </div>)}
                </>
                ) : null}
              {it[2][9] ? (
                <>{it[2][9] === 'ccbysa40' ? (
                <div class={style.pimgcrd}>
                  <a href='https://creativecommons.org/licenses/by-sa/4.0/'>CC-BY-SA 4.0</a>
                  <meta itemProp="license" content="https://creativecommons.org/licenses/by-sa/4.0/" />
                  <meta itemProp="acquireLicensePage" content="https://creativecommons.org/licenses/by-sa/4.0/" />
                </div>
                ) : (
                  <div>
                  {it[2][10] ? (
                    <div class={style.pimgcrd}>
                      <a href={it[2][10]}>{it[2][9]}</a>
                      <meta itemProp="license" content={it[2][10]} />
                      <meta itemProp="acquireLicensePage" content={it[2][10]} />
                    </div>
                  ) : (
                  <div class={style.pimgcrd}>{it[2][9]}</div>)}
                </div>
                )}
                </>
              ) : null

              }

              </figcaption>
                ) : null}

            </figure>
            </div>
          ) : null}

          {!noImagesD && it[1] === 'p' && it[4] ? (
          <>
             {it[4] === 'y' ? (
              <div>
              <iframe
                class={style.ytifr}
                // src={`${it[2]}?autoplay=0&mute=0&controls=0&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=3"`}
                // src={`${it[2]}?autoplay=0&mute=0&controls=0&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=3"`}
                src={`${it[2]}?autoplay=0&mute=0&controls=0&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&enablejsapi=1"`}
                frameBorder="0"
                // allow="autoplay; encrypted-media"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="1"
                title="YouTube video player"
                loading="lazy"
                width="560"
                height="315"
              />
              </div>) : null}

            {it[4] === 'yp' ? (
              <div class="ypfr">
              <Suspense fallback={<div>loading...</div>}>
                <YouTube url={it[2]} />
              </Suspense>
              </div>
            ) : null}
            {it[4] === 's' ? (
              <div class="scfr">
              <Suspense fallback={<div>loading...</div>}>
                <SoundCloud url={it[2]} />
              </Suspense>
              </div>
            ) : null}
            {it[4] === 'f' ? (
              <Suspense fallback={<div>loading...</div>}>
                <Facebook url={it[2]} />
              </Suspense>
            ) : null}
            {it[4] === 'v' ? (
              <Suspense fallback={<div>loading...</div>}>
                <Vimeo url={it[2]} />
              </Suspense>
            ) : null}
          </>
          ) : null}

          {!noImagesD && it[1] === 'w' ? (
          <>
            {it[4] === 'f' ? (
              <div class={style.embtwf}>
              <div class="twffr">
              <Suspense fallback={<div class={style.loading}>LOOOOOOOADING...</div>}>
                <TwitterFeed
                  profile={it[2]}
                />
              </Suspense>
              </div>
              </div>
            ) : null}
            {it[4] === 't' ? (
              <Suspense fallback={<div>loading...</div>}>
                <TwitterTweet
                  id={it[2]}
                />
              </Suspense>
            ) : null}
          </>
          ) : null}
            </Fragment>
          )) : null}
          <div class={style.lastupd}>
            <br />
            <br />
            <code>
            {langD === 'de' ? (<span>letzte Überarbeitung: {new Date(con.u).toLocaleDateString('de')}</span>
            ) : (
            <span>last update: {new Date(con.u).toLocaleDateString('en')}</span>)}
            </code>
          </div>
        </div>
        </div>

        <div class={darkM ? style.dm : style.lm}>
        <button
          ref={openShareRef}
          class={style.opnbtn}
          onClick={() => openShare()}
          aria-label="share on social media"
          aria-haspopup="true"
        >
          <ShareIcon />
        </button>
        {shareIsOpen ? (
          <Suspense>
          <Share2
            title={con.h.replace(/\s/g, '%20')}
            darkM={darkM}
          />
          </Suspense>
        ) : null }
         <div class={style.scrtopfr}>
            <button onClick={() => scrollToTop()} aria-label="scroll to top" />
          </div>
        </div>
        </div>
      ) : (
        <>
        {is404 ? <Err404 /> : <LoadingPage />}
      </>
      )
        }
      </PageContainer>
    </Template>
  );
};

export default Post;
