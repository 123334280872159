import style from './failedToFetch.css';

const FailedToFetch = ({ lang, mode }) => (
  <div class={mode ? style.dm : style.lm}>
    {lang === 'de' ? (
    <>
      <h1>Inhalte konnten nicht geladen werden.</h1>
      <div>
      <h2>Die Verbindung zum Server ist fehlgeschlagen.</h2>
      <p class={style.mark}>Überprüfe die Verbindung zum Internet oder lade die Seite neu.</p>
      </div>
    </>
    ) : (
    <>
      <h1>Could not load content.</h1>
      <div>
      <h2>Failed to connect to server.</h2>
      <p class={style.mark}><code>Check your internet connection or reload the site.</code></p>
      </div>
    </>
    )}
  </div>
);

export default FailedToFetch;
