import { Link, route } from 'preact-router';
import {
  useEffect, useReducer, useRef, useState,
} from 'preact/hooks';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import getPath from '../../utils/getPath';
import scrollToTop from '../../utils/scrolls';
import style from './style.css';

const Header = ({ upd }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [imgQualTxt, setImgQualTxt] = useState('');
  const [noImgTxt, setNoImgTxt] = useState('');
  const titleRef = useRef();
  const menRef = useRef();
  const menBtn = useRef();
  const menNoFlash = useRef();
  const menCat = useRef();
  const menNav = useRef();
  const menCset = useRef();
  const connectionSettings = useConnectionSettings();
  const setSaveDataD = connectionSettings.setSaveData;
  const saveDataD = connectionSettings.saveData;
  const noImagesD = connectionSettings.noImages;
  const setNoImagesD = connectionSettings.setNoImages;
  const darkModeD = connectionSettings.darkMode;
  const setDarkModeD = connectionSettings.setDarkMode;
  const contDat = useContentData();
  const langD = contDat.lang;
  const forceUpdate = useReducer((bool) => !bool)[1];
  const loadInitialPostsD = contDat.loadInitialPosts;
  const setContentDataD = contDat.setContentData;
  const setContentArrD = contDat.setContentArr;
  const setLangD = contDat.setLang;

  // browser address bar
  let vh = '100vh';
  if (typeof window !== 'undefined') {
    if (window.innerHeight) {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vheight', `${vh}px`);
    }
  }

  const doSaveData = () => {
    if (!saveDataD) {
      localStorage.setItem('sd', 's');
      setSaveDataD(true);
      setImgQualTxt(langD === 'de' ? 'Bilder mit niedriger Aufloesung' : 'low resolution images');
    } else {
      localStorage.setItem('sd', 'n');
      setSaveDataD(false);
      setImgQualTxt(langD === 'de' ? 'Bilder mit nomarler Aufloesung' : 'standard resolution images');
    }
  };

  const doSetImages = () => {
    if (!noImagesD) {
      localStorage.setItem('i', 'n');
      setNoImagesD(true);
      setNoImgTxt(langD === 'de' ? 'keine Bilder, Clips und Tweets anzeigen' : 'show no images, clips and tweets');
    } else {
      localStorage.setItem('i', 'y');
      setNoImagesD(false);
      setNoImgTxt(langD === 'de' ? 'Bilder, Clips und Tweets anzeigen' : 'show images, clips and tweets');
    }
  };

  const doSetDarkMode = () => {
    const getB = document.getElementsByTagName('html')[0];
    if (!darkModeD) {
      setDarkModeD(true);
      getB.classList.add('dark');
      localStorage.setItem('d', 'y');
      menNav.current.classList.add(style.navd);
      menNav.current.classList.remove(style.navl);

      menCset.current.classList.add(style.navl);
      menCset.current.classList.remove(style.navd);
    } else {
      setDarkModeD(false);
      getB.classList.remove('dark');
      localStorage.setItem('d', 'n');
      menNav.current.classList.add(style.navl);
      menNav.current.classList.remove(style.navd);

      menCset.current.classList.add(style.navd);
      menCset.current.classList.remove(style.navl);
    }
  };

  const onOpenMenu = () => {
    scrollToTop();
    const wind = document.getElementById('app');
    // aria / tabindex
    const getButtons = document.querySelectorAll('main button');
    const getLinks = document.querySelectorAll('main a');
    const getFooterLinks = document.querySelectorAll('footer a');
    const getImgs = document.querySelectorAll('main figure div img');
    // ===
    if (!menuOpen) {
      wind.classList.add('ovrflh');
      titleRef.current.classList.add(style.mnbpn);
      menRef.current.classList.add(style.opnmn);

      menNav.current.classList.remove(style.nodisp);
      menNav.current.classList.add(style.nav);

      menCset.current.classList.remove(style.nodisp);
      menCset.current.classList.add(style.nav);
      if (menCat.current) {
        menCat.current.classList.add(style.nodisp);
      }

      menBtn.current.classList.add(style.mbtnfrpn);

      // accessibility
      if (getImgs) {
        getImgs.forEach((it) => {
          it.setAttribute('tabindex', '-1');
          it.removeAttribute('aria-hidden', true);
        });
      }
      if (getButtons) {
        getButtons.forEach((it) => {
          it.setAttribute('tabindex', '-1');
          it.setAttribute('aria-hidden', true);
        });
      }
      if (getLinks) {
        getLinks.forEach((it) => {
          it.setAttribute('tabindex', '-1');
          it.setAttribute('aria-hidden', true);
        });
      }
      if (getFooterLinks) {
        getFooterLinks.forEach((it) => {
          it.setAttribute('tabindex', '-1');
          it.setAttribute('aria-hidden', true);
        });
      }
    } else {
      titleRef.current.classList.remove(style.mnbpn);

      menNav.current.classList.add(style.nodisp);
      menNav.current.classList.remove(style.nav);

      menCset.current.classList.add(style.nodisp);
      menCset.current.classList.remove(style.nav);

      menRef.current.classList.remove(style.opnmn);
      if (menCat.current) {
        menCat.current.classList.remove(style.nodisp);
      }

      wind.classList.remove('ovrflh');
      menBtn.current.classList.remove(style.mbtnfrpn);

      // accessibility
      if (getImgs) {
        getImgs.forEach((it) => {
          it.setAttribute('tabindex', '0');
          it.removeAttribute('aria-hidden', true);
        });
      }
      if (getButtons) {
        getButtons.forEach((it) => {
          it.removeAttribute('tabindex', '-1');
          it.removeAttribute('aria-hidden', true);
        });
      }
      if (getLinks) {
        getLinks.forEach((it) => {
          it.removeAttribute('tabindex', '-1');
          it.removeAttribute('aria-hidden', true);
        });
      }
      if (getFooterLinks) {
        getFooterLinks.forEach((it) => {
          it.removeAttribute('tabindex', '-1');
          it.removeAttribute('aria-hidden', true);
        });
      }
    }
    setMenuOpen(!menuOpen);
  };

  if (typeof window !== 'undefined') {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = () => {
      let addressBarHeight = 0;
      if ('navigator' in window) {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          addressBarHeight = window.outerHeight - window.innerHeight;
        }
      }
      const currentScrollPos = window.pageYOffset + addressBarHeight;
      // 144: prevent hide menu button on bouncing down addressbar on mobiles/IPhone when sroll to top

      if (currentScrollPos <= 144 || window.pageYOffset <= 7) {
        menBtn.current.classList.remove(style.hdscrl);
      } else if (prevScrollpos > currentScrollPos || menuOpen) {
        menBtn.current.classList.remove(style.hdscrl);
      } else {
        menBtn.current.classList.add(style.hdscrl);
      }
      prevScrollpos = currentScrollPos;
    };
  }

  const doUpdate = () => {
    onOpenMenu();
    forceUpdate((n) => !n);
    upd();
  };

  const switchLang = (lang) => {
    const clearS = async () => {
      setContentDataD(() => null);
      setContentArrD(() => '');
      setLangD(lang);
      route(`/${lang}`, true);
    };
    clearS()
      .then(() => {
        loadInitialPostsD();
      });
    if (menuOpen) {
      onOpenMenu();
    }
    localStorage.setItem('la', lang === 'en' ? 'en' : 'de');
  };

  useEffect(() => {
    console.log('ping from header/index.js, v4');
    // prevent flash of menu onMount... remove class which sets transition time to 0 after mount
    setTimeout(() => {
      menNoFlash.current.classList.remove(style.noflash);
    }, 200);
    return () => {
      setMenuOpen(false);
      setImgQualTxt(null);
      setNoImgTxt(null);
      // menRef.current = null;
      // titleRef.current = null;
      // menBtn.current = null;
      // menNoFlash.current = null;null
      // menCat.current = null;
      // menNav.current = null;
      // menCset.current = null;
    };
  }, []);

  useEffect(() => {
    if (!saveDataD) {
      setImgQualTxt(langD === 'de' ? <span>Bilder mit hoher Auflösung<br />(normale Verbindung) </span> : <span> load standard resolution images <br /> (normal connection)</span>);
    } else {
      setImgQualTxt(langD === 'de' ? <span>Bilder mit niedriger Auflösung <br />(langsame Verbingung)</span> : <span> load low resolution images <br /> (slow connection)</span>);
    }
    if (!noImagesD) {
      setNoImgTxt(langD === 'de' ? <span>Bilder, Clips & Tweets laden <br />(gute Verbindung)</span> : <span>load images, clips and tweets<br />(good connection)</span>);
    } else {
      setNoImgTxt(langD === 'de' ? <span>keine Bilder, Clips & Tweets anzeigen<br />(schlechte Verbindung / Daten sparen)</span> : <span>load no images, clips and tweets <br />(bad connection / save data)</span>);
    }
  }, [saveDataD,
    langD,
    noImagesD,
    setNoImagesD]);

  useEffect(() => () => ({
    useContentData, useConnectionSettings,
  }));

  return (
    <header class={darkModeD ? style.cold : style.coll}>
  <div ref={menNoFlash} class={style.noflash}>
    <div class={style.mbtnfr} ref={menBtn}>
      <button class={style.mbtn} type="button" onClick={() => onOpenMenu()}>
        {langD === 'en' ? <>{menuOpen ? 'back' : 'menu'}</> : null}
        {langD === null ? <>{menuOpen ? 'back' : 'menu'}</> : null}
        {langD === 'de' ? <>{menuOpen ? 'zurück' : 'menu'}</> : null}
      </button>
    </div>
      {(getPath() === '/' || getPath() === '/en') ? <h1 ref={menCat} class={style.catside}>HOME</h1> : null}
      {(getPath() === '/blog/c/s' || getPath() === '/en/blog/c/s') ? <h1 ref={menCat} class={style.catside}>STUFF</h1> : null}
      {(getPath() === '/blog/c/n' || getPath() === '/en/blog/c/n') ? <h1 ref={menCat} class={style.catside}>NEWS</h1> : null}
      {(getPath() === '/blog/c/m' || getPath() === '/en/blog/c/m') ? <h1 ref={menCat} class={style.catside}>MUSIC</h1> : null}
    <div class={style.men} ref={menRef}>
      <nav ref={menNav} class={style.nodisp}>
        {langD === 'de' ? (
        <>
          <Link onClick={() => doUpdate()} href="/">Home</Link>
          <Link onClick={() => doUpdate()} href="/blog/c/m">Musik</Link>
          <Link onClick={() => doUpdate()} href="/blog/c/s">Zeugs</Link>
          <Link onClick={() => doUpdate()} href="/blog/c/n">News</Link>
          <Link onClick={() => doUpdate()} href="/about">About</Link>
        </>
        ) : null}
        {langD === 'en' ? (
        <>
          <Link onClick={() => doUpdate()} href="/en">home</Link>
          <Link onClick={() => doUpdate()} href="/en/blog/c/m">music</Link>
          <Link onClick={() => doUpdate()} href="/en/blog/c/s">stuff</Link>
          <Link onClick={() => doUpdate()} href="/en/blog/c/n">news</Link>
          <Link onClick={() => doUpdate()} href="/en/about">about</Link>
        </>
        ) : null}

        </nav>
      <div class={style.csetfr}>
        <div ref={menCset} class={style.nodisp}>
          <div class={style.langview}>
            <div class={style.btngrp}>
              <label class={style.csetitem}>
                <span>{langD === 'de' ? 'Sprache' : 'language'}</span>
                <button onClick={() => switchLang(langD === 'en' ? '' : 'en')}>{langD === 'de' ? 'Deutsch' : 'english'}</button>
              </label>
            </div>
            <br />
            <div class={style.btngrp}>
              <label class={style.csetitem}>
                <span>{langD === 'de' ? 'Night Mode' : 'night mode'}</span>
                <button onClick={() => doSetDarkMode()}>{!darkModeD ? (<>
                {langD === 'de' ? 'aus' : 'off'}</>) : (<>{langD === 'de' ? 'an' : 'on'}</>)}</button>
              </label>
            </div>
          </div>
          <div class={style.datas}>
            <div>
              <button onClick={() => doSetImages()}>{noImgTxt}</button>
            </div>
            <br />
            <div hidden={noImagesD}>
              <button onClick={() => doSaveData()}>{imgQualTxt}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class={style.sdfr}>
      <div class={style.sdbar}>
        <div ref={titleRef} class={style.title}>
          <Link onClick={() => forceUpdate((n) => !n)} href={langD === 'de' ? '/' : '/en'}>
            <span class={style.b}>b</span>eervirus
          </Link>
        </div>
      </div>
    </div>
  </div>
  <div id="bganchor" />
  </header>
  );
};

export default Header;
