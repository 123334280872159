import {
  useContext, useEffect, useState,
} from 'preact/hooks';

const { createContext } = require('preact');

const connectionContext = createContext();

export const useConnectionSettings = () => useContext(connectionContext);

function useProvideConnection() {
  const [saveData, setSaveData] = useState(false);
  const [slowConnection, setSlowConnection] = useState(false);
  const [noImages, setNoImages] = useState(false);
  const [supportsWebp, setSupportsWebp] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [isConsentOK, setIsConsentOK] = useState(null);

  const checkWep = () => {
    const img = new Image();
    img.onload = () => {
      setSupportsWebp(!!(img.height > 0 && img.width > 0));
    };
    img.onerror = () => {
      setSupportsWebp(false);
    };
    img.src = 'data:image/webp;base64, UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
  };

  useEffect(() => {
    // check for webP
    // ... and IOs if img load="lazy" supported
    checkWep();
    if (localStorage.getItem('sd') === 's') {
      setSaveData(true);
    } else if (typeof navigator !== 'undefined') {
      if ('connection' in navigator) {
        if (navigator.connection.saveData === true) {
          setSaveData(true);
        }
        if (navigator.connection.effectiveType === '3g'
              || navigator.connection.effectiveType === '2g'
              || navigator.connection.effectiveType === 'slow-3g'
        ) {
          setSlowConnection(true);
        }
      }
    }
    // check if lazy load is supported
    // if ('loading' in HTMLImageElement.prototype) {
    //   setLoadLazyIMG(true);
    // }

    if (localStorage.getItem('analyticsConsent') === 'ok') {
      setIsConsentOK(true);
    } else {
      setIsConsentOK(false);
    }

    if (localStorage.getItem('d')) {
      if (localStorage.getItem('d') === 'y') {
        setDarkMode(true);
      }
    } else if (typeof window !== 'undefined') {
      if ('matchMedia' in window) {
        if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setDarkMode(true);
          }
        }
      }
    }

    if (localStorage.getItem('i') === 'n') {
      setNoImages(true);
    }

    return () => {
      // unsubscribe states
      setSaveData(null);
      setSlowConnection(null);
      setNoImages(null);
      setSupportsWebp(null);
      setDarkMode(null);
    };
  }, []);

  return {
    saveData,
    setSaveData,
    slowConnection,
    setSlowConnection,
    noImages,
    setNoImages,
    supportsWebp,
    setDarkMode,
    darkMode,
    isConsentOK,
    setIsConsentOK,
  };
}

export function ProvideConnectionSettings({ children }) {
  const connectionBase = useProvideConnection();

  return (
    <connectionContext.Provider value={connectionBase}>
      {children}
    </connectionContext.Provider>
  );
}
