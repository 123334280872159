import style from './pageContainer.css';

const PageContainer = ({ children }) => (
  <div class={style.pfrm}>
    <div class={style.pcont}>
      {children}
    </div>
  </div>
);

export default PageContainer;
