import { h } from 'preact';
import { Router } from 'preact-router';
import { HelmetProvider } from 'react-helmet-async';

// import render from 'preact-render-to-string';

// Code-splitting is automated for `routes` directory
// import Dev from '../routes/dev/index.jsx';
// import HomePort from '../routes/dev/HomeProt.jsx';
import Main from '../routes/main/Main.jsx';
import { ProvideContentData } from '../chooks/useContentData.jsx';
import Post from '../routes/post/Post.jsx';
import About from '../routes/about/About.jsx';
import { ProvideConnectionSettings } from '../chooks/useConnectionSettings.jsx';
import Cats from '../routes/cats/Cats.jsx';
import Err404Page from '../routes/err404/Err404Page.jsx';

const App = () => (
  <div id="app">
    <ProvideConnectionSettings>
      <ProvideContentData>
        <HelmetProvider>
          <Router>
            <Main exact path="" lang="de" />
            <Main exact path="en" lang="en" />
            <Cats exact path="blog/c/:k" lang="de" />
            <Cats exact path="en/blog/c/:k" lang="en" />
            <Post exact path="en/blog/:dt" ty="en" />
            <Post exact path="blog/:dt" ty="de" />
            <About exact path="about" lang="de" />
            <About exact path="en/about" lang="en" />
            <Err404Page default />
          </Router>
        </HelmetProvider>
      </ProvideContentData>
    </ProvideConnectionSettings>
  </div>
);

export default App;
