import { useEffect, useRef } from 'preact/hooks';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import { cookieConsentMsgDE, cookieConsentMsgEN } from '../../constants/labelsAndMetas';
import style from './consent.css';

const Consent = () => {
  const connectionSetting = useConnectionSettings();
  const contDat = useContentData();
  const msgContRef = useRef();
  const setConsentIsOKD = connectionSetting.setIsConsentOK;
  const isConsentOKD = connectionSetting.isConsentOK;
  const isDarkMode = connectionSetting.darkMode;
  const langD = contDat.lang;

  const doConsentOK = () => {
    localStorage.setItem('analyticsConsent', 'ok');
    setConsentIsOKD(true);
    const getMain = document.getElementsByTagName('main')[0];
    getMain.classList.remove('doblur');
    msgContRef.current.classList.add('noshowmessage');
  };

  useEffect(() => {
    setTimeout(() => {
      const getMain = document.getElementsByTagName('main')[0];
      if (getMain && isConsentOKD !== null) {
        getMain.classList.add('doblur');
        msgContRef.current.classList.remove('noshowmessage');
        setTimeout(() => {
          msgContRef.current.classList.add('showmessage');
        }, 200);
        if (isDarkMode) {
          msgContRef.current.classList.add(style.dm);
          msgContRef.current.classList.remove(style.lm);
        } else {
          msgContRef.current.classList.add(style.lm);
          msgContRef.current.classList.remove(style.dm);
        }
      }
    }, 5000);
  }, [isDarkMode, isConsentOKD]);

  return (
    <div ref={msgContRef} id="consentmessage" class="noshowmessage">
    <div class={style.ccsfr}>
      <div class={style.ccsin}>
      <h3>COOKIES</h3>
      <div>
      {langD === 'de' ? (
        <div>
          <p>{cookieConsentMsgDE}</p>
        </div>
      ) : (
      <div>
       <p>{cookieConsentMsgEN}</p>
        </div>
      )}
      </div>
      <div class={style.btnfr}>
      <button onClick={() => doConsentOK()}>
        {langD === 'de' ? 'Ich stimme zu' : 'I agree'}
      </button>
      </div>
      {langD === 'de' ? (
      <p>Infos über <a class="tlink" target="_blank" rel="noopener noreferrer" href='https://www.eff.org/de/deeplinks/2019/07/sharpening-our-claws-teaching-privacy-badger-fight-more-third-party-trackers' >Cookies und wie Tracking verhindert werden kann.</a></p>
      ) : (
      <p>Info on <a class="tlink" target="_blank" rel="noopener noreferrer" href='https://www.eff.org/de/deeplinks/2019/07/sharpening-our-claws-teaching-privacy-badger-fight-more-third-party-trackers' >cookies and how to prevent tracking.</a></p>
      )}
    </div>
    </div>
    </div>
  );
};

export default Consent;
