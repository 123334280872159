import { Link } from 'preact-router';
import { useEffect } from 'preact/hooks';
import ReactGA from 'react-ga';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import Consent from '../consent/Consent.jsx';
import style from './footer.css';
import anchor from '../../routes/post/icons/anchor.svg';

const Footer = () => {
  const contDat = useContentData();
  const connectionSetting = useConnectionSettings();
  const isConsentOKD = connectionSetting.isConsentOK;
  const langD = contDat.lang;
  const darkModeD = connectionSetting.darkMode;

  useEffect(() => {
    if (isConsentOKD) {
      ReactGA.initialize('UA-132342687-3');
      ReactGA.pageview(window.location.pathname);
    }
  }, [isConsentOKD]);

  return (
  <footer class={darkModeD ? style.dm : style.lm}>
    <div class={style.ftrpos}>
    <div class={style.ftfr}>
      <nav>
        {langD === 'de' ? (
          <>
            <Link class={style.lelink} href="/">Startseite</Link>
            <Link class={style.cats} href="/blog/c/s">Zeugs</Link>
            <Link class={style.cats} href="/blog/c/m">Musik</Link>
            <Link class={style.cats} href="/blog/c/n">News</Link>
            <Link href="/about">About</Link>
          </>
        ) : (
          <>
            <Link class={style.lelink} href="/en">home</Link>
            <Link class={style.cats} href="/en/blog/c/s">stuff</Link>
            <Link class={style.cats} href="/en/blog/c/m">music</Link>
            <Link class={style.cats} href="/en/blog/c/n">news</Link>
            <Link href="/en/about">about</Link>
          </>
        )}
      </nav>
      <Link class={style.cats} href="https://wefrick.com" target="_black" rel="nooperner noreferrer">coding</Link>
      <span class={style.logofr} >
          <img class={style.logoicon} src={anchor} alt="logo" />
        </span>
    </div>
    {isConsentOKD ? null : (
      <Consent />
    ) }
    </div>
  </footer>
  );
};

export default Footer;
