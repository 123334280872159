import { getCurrentUrl } from 'preact-router';
import { useEffect } from 'preact/hooks';
import { Helmet } from 'react-helmet-async';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import PageContainer from '../../components/template/PageContainer.jsx';
import Template from '../../components/template/Template.jsx';
import { mainURL, metaDescriptionAboutDE, metaDescriptionAboutEN } from '../../constants/labelsAndMetas';
import style from './aboutStyle.css';

const About = ({ lang }) => {
  const contDat = useContentData();
  const setLangD = contDat.setLang;
  const connectionSettings = useConnectionSettings();
  const darkM = connectionSettings.darkMode;

  useEffect(() => {
    setLangD(lang);
  }, [lang, setLangD]);

  return (
  <Template>
     <Helmet
        htmlAttributes={{ lang }}
        meta={[
          { name: 'description', content: lang === 'de' ? metaDescriptionAboutDE : metaDescriptionAboutEN },
        ]}
      >
        <title>beervirus | ABOUT</title>
        <link rel="canonical" href={`${mainURL}${getCurrentUrl()}`} />
      </Helmet>
    <PageContainer>
      <div class={darkM ? style.dm : style.lm}>
        {lang === 'en' ? (
        <>
          <h1>ABOUT ENGLISH</h1>
          <p>About this site, impressum, contact data, social links and so on... About this site, impressum, contact data, social links and so on... About this site, impressum, contact data, social links and so on...</p>
          <p>About this site, impressum, contact data, social links and so on...</p>
          <p>About this site, impressum, contact data, social links and so on...</p>
          <p>About this site, impressum, contact data, social links and so on...</p>
        </>
        ) : (
        <>
          <h1>ABOUT DEUTSCH</h1>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw..., Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
          <p>Über diese Seite, Impressum, Kontaktinfos, Social links usw...</p>
        </>
        )}
    <div>asdf</div>
      </div>
    </PageContainer>
  </Template>
  );
};

export default About;
