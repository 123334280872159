export const mainURL = 'https://beervirus.com';

export const defaultPostImage = 'https://cmskinny9c38352ba2f9a4b9e9c990f6254cb553794136-dev.s3.us-east-2.amazonaws.com/public/1604545599011/joseph-chan-Q--730ajUcQ-unsplash-6vSOtOHoh-1440w.jpeg';
export const publisherLogo = 'https://mylogo.com/logo.jpg';

export const metaDescriptionHomeDE = 'meta description HOME DE blabla';
export const metaDescriptionHomeEN = 'meta description HOME EN bla bla';

export const metaDescriptionAboutDE = 'meta description ABOUT DE bla bla';
export const metaDescriptionAboutEN = 'meta description ABOUT EN bla bla';

export const metaDescriptionCatStuffDE = 'meta description DE category stuff';
export const metaDescriptionCatStuffEN = 'meta description EN category stuff';

export const metaDescriptionCatNewsDE = 'meta descrptiontion DE category News';
export const metaDescriptionCatNewsEN = 'meta descrptiontion EN category News';

export const metaDescriptionCatMusicDE = 'meta description DE category Music';
export const metaDescriptionCatMusicEN = 'meta description EN category Music';

export const cookieConsentMsgDE = 'Diese Webseite benutzt GoogleAnalytics und setzt nach Deiner Zustimmung entsprechende Cookies. Die daraus gewonnenen Informationen dienen in erster Linie meiner persönlichen Neugierde, also ob mein Geschreibsel gelesen wird. Nutzerdaten können von Google zu Marketingzwecken ausgewertet oder an Drittparteien weitergegeben werden';
export const cookieConsentMsgEN = 'This website uses GoogleAnalytics and sets cookies after your consent. Obtained information serve mainly my personal curiosity - if my writing is read by others. User data are analyzed by Google and can be passed on to third parties for marketing purposes.';
