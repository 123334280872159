// fetch feed from DynamoDB, returns post feed (arr) and lastEvaluatedItem (pos)
// ty -> language: 'de' || 'eb'
// dt -> get posts older than 'dt'

import { APIURLPOSTFEEDlpost } from '../constants/urls';

// l -> limit, number of requested posts
async function fetchFeed(ty, dt) {
  let arr = [];
  let pos = 0;
  let err = false;
  await fetch(`${APIURLPOSTFEEDlpost}?t=${ty}&d=${dt.toString()}&l=5}`)
    .then((res) => res.json())
    .then((d) => {
      d.Items.forEach((it) => {
        arr = [...arr, it];
      });
      pos = d.LastEvaluatedKey;
    })
    .catch(() => {
      err = true;
    });
  return { arr, pos, err };
}

export default fetchFeed;
