import { Link } from 'preact-router';
import { useConnectionSettings } from '../../chooks/useConnectionSettings.jsx';
import { useContentData } from '../../chooks/useContentData.jsx';
import style from './err404.css';

const Err404 = () => {
  const contDat = useContentData();
  const langD = contDat.lang;
  const connectionSettings = useConnectionSettings();
  const darkM = connectionSettings.darkMode;

  return (
    <div class={darkM ? style.dm : style.lm}>
    {langD === 'de' ? (
      <div class={style.fr404}>
        <h1>Seite nicht gefunden / 404</h1>
        <br />
         <p>Diese Seite gibt es nicht. Vertippt?!</p>
         <Link href='/'>Zurück zur Startseite</Link>
         </div>
    ) : (
      <div class={style.fr404}>
        <h1>Page not found / 404</h1>
        <br />
        <p>This page doesn't exist. Typo?!</p>
        <Link href='/'>Back to Home</Link>
      </div>
    )}
    </div>

  );
};

export default Err404;
