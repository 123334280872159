const { default: PageContainer } = require('../../components/template/PageContainer.jsx');
const { default: Template } = require('../../components/template/Template.jsx');
const { default: Err404 } = require('./Err404.jsx');

const Err404Page = () => (
  <Template>
    <PageContainer>
      <Err404 />
    </PageContainer>
  </Template>
);

export default Err404Page;
